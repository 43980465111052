import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'components/Layout';
import RichTextWithModal from 'components/RichTextWithModal';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import { usePageTracking } from 'helpers/pageTracking';
import { CsHero } from 'types/contentStack';
import { ButtonWithMargin, RichTextAfterButton } from './styles';

type CsCookiePolicy = {
  data: {
    csPetCookiePolicy: {
      hero: CsHero;
      button_text: string;
      meta_title: string;
      rich_text_after: string;
      rich_text_before: string;
    };
  };
};

export const query = graphql`
  query {
    csPetCookiePolicy {
      hero {
        heading
        subheading
      }
      button_text
      meta_title
      rich_text_after
      rich_text_before
    }
  }
`;

/* istanbul ignore next */
const CookiePolicyPage: React.FC<CsCookiePolicy> = ({
  data: {
    csPetCookiePolicy: {
      hero,
      meta_title,
      rich_text_after,
      button_text,
      rich_text_before,
    },
  },
}) => {
  usePageTracking(meta_title);

  return (
    <Layout
      heading={hero.heading}
      subheading={hero.subheading}
      metaTitle={meta_title}
      pageTitle={PageTitle.CookiePolicy}>
      <Grid>
        <GridItem desktop={8} tabletLandscape={8} tabletPortrait={6}>
          <RichTextWithModal html={rich_text_before} pageTitle={PageTitle.CookiePolicy} />
          <ButtonWithMargin
            className="ot-sdk-show-settings"
            onClick={() => trackTextButtonClick(PageTitle.CookiePolicy, button_text)}>
            {button_text}
          </ButtonWithMargin>
          <RichTextAfterButton
            html={rich_text_after}
            pageTitle={PageTitle.CookiePolicy}
          />
          <div id="ot-sdk-cookie-policy" />
        </GridItem>
      </Grid>
    </Layout>
  );
};

export default CookiePolicyPage;
